import { createEffect, createStore } from 'effector'
import { getCustomer, createCustomer, updateCustomer, getLoyaltyCardsForCustomer, createLoyaltyCard, deleteLoyaltyCard } from '../api';
import { createEffectorDebugger } from 'lib/effectorDebugger'
import { Customer, LoyaltyCard } from '../types'
import { createRecord, DataRecord, NoRecord } from 'lib/maybeType'


const createCustomerFx = createEffect({
  name: 'create customer',
  handler: (body) => createCustomer(body)
})

const loadCustomerFx = createEffect({
  name: 'load client on card input',
  handler: ({ customerUid }) => getCustomer(customerUid)
})
loadCustomerFx.done.watch(({ result }) => loadCustomerLoyaltyCardsFx({ customerUid: result.data.uid }))


const updateCustomerFx = createEffect({
  name: 'update client',
  handler: (body) => updateCustomer(body)
})
updateCustomerFx.done.watch(({ params }) => loadCustomerFx({ customerUid: params.uid }))



const loadCustomerLoyaltyCardsFx = createEffect({
  name: 'load loyalty cards for customer',
  handler: ({ customerUid }) => getLoyaltyCardsForCustomer(customerUid)
})

const addCustomerLoyaltyCardFx = createEffect({
  name: 'create loyalty card for customer',
  handler: ({ customerUid, cardNumber }) => createLoyaltyCard(customerUid, { cardNumber })
})
addCustomerLoyaltyCardFx.done.watch(({ params }) => loadCustomerLoyaltyCardsFx(params))

const deleteCustomerLoyaltyCardFx = createEffect({
  name: 'delete loyalty card for customer',
  handler: ({ customerUid, cardNumber }) => deleteLoyaltyCard(customerUid, cardNumber)
})
deleteCustomerLoyaltyCardFx.done.watch(({ params }) => loadCustomerLoyaltyCardsFx(params))

const $customer = createStore(createRecord<Customer>())
  .on(loadCustomerFx, () => createRecord())
  .on(loadCustomerFx.fail, (_, __) => createRecord())
  .on(loadCustomerFx.done, (_, { result }) => createRecord(result.data))

$customer.watch(createEffectorDebugger('stores.pages.customer-management.customer'))


const $loyaltyCards = createStore(createRecord<LoyaltyCard[]>())
  .on(loadCustomerFx, () => createRecord())
  .on(loadCustomerLoyaltyCardsFx, () => createRecord())
  .on(loadCustomerLoyaltyCardsFx.fail, (_, __) => createRecord())
  .on(loadCustomerLoyaltyCardsFx.done, (_, { result }) => createRecord(result.data))

$loyaltyCards.watch(createEffectorDebugger('stores.pages.customer-management.loyalty-cards'))

export {
  loadCustomerFx,
  updateCustomerFx,
  createCustomerFx,
  loadCustomerLoyaltyCardsFx,
  addCustomerLoyaltyCardFx,
  deleteCustomerLoyaltyCardFx,
  $customer,
  $loyaltyCards
}
