import cx from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { $sidebar, toggleSidebar } from 'common/models/sidebar'
import { useStore } from 'effector-react'
import Icon from '../elements/Icon'
import { expireToken } from '../../../features/authentication';


function Sidebar() {
  const sidebarOpen = useStore($sidebar)
  return (
    <div className="sidebar phantasm__sidebar">
      <div className="scrollbar-container sidebar-nav sidebar__scrollbar-container">
        <ul className="nav">
          <li className="nav-title">PoS Tools</li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/cash-register">
              <Icon className="nav-icon" type='cash-register' size='sm' />
              Cash register
            </NavLink>
          </li>
          <li className="nav-title">Customer management</li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/customers">
              <Icon className="nav-icon" type='users' size='sm' />
              Customers
            </NavLink>
          </li>
          {/*
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin/loyalty-cards">
              <Icon className="nav-icon" type='credit-card' size='sm' />
              Loyalty cards
            </NavLink>
          </li>
          */}
          <li className="nav-title">Shift management</li>
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                expireToken()
              }}>
              <Icon className="nav-icon" type='log-out' size='sm' />
              End shift
            </a>
          </li>
        </ul>
      </div>
      <button
        className={
          cx(
            'sidebar-minimizer mt-auto phantasm-sidebar__minimizer',
            { 'expanded': sidebarOpen }
          )
        }
        onClick={() => toggleSidebar()}>
        <Icon type={sidebarOpen ? 'move-left' : 'move-right'} size='md' />
      </button>
    </div>
  )
}

export default Sidebar
