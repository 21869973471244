import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardHeader, CardBody, CardTitle, FormGroup, ButtonGroup, Table, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import Icon from 'common/ui/elements/Icon'
import CustomerSearch from '../../ui/modules/CustomerSearch'

function SearchProfilePage() {
  return (
    <div className="p-page container-fluid p-customer-search-page">
      {/*<h2>Add new customer</h2>*/}
      <div className="p-page-content">
        <CustomerSearch value={''} />
        <Row>
          <Col sm={12}>
            <div className="p-placeholder">
              <Icon className="p-icon" type="scan" size="lg" />
              <h6>Scan customer card, QR, or enter customer phone and hit Enter</h6>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SearchProfilePage
