import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Card, CardHeader, CardBody, CardTitle, FormGroup, ButtonGroup, Table, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import { EffectorForm, EffectorField, createFormContext } from 'lib/effectorForm'
import Button from 'common/ui/elements/Button'
import Loader from 'common/ui/elements/Loader'
import Icon from 'common/ui/elements/Icon'
import MetaBlock from 'common/ui/elements/MetaBlock'
import { sample } from 'effector'
import { useStore } from 'effector-react'
import {
    loadCustomerFx,
    $customer,
    updateCustomerFx,
    loadCustomerLoyaltyCardsFx,
    $loyaltyCards,
    addCustomerLoyaltyCardFx,
    deleteCustomerLoyaltyCardFx,
} from '../../models/customer'
import useDisplayState from 'lib/useDisplayState'
import DatePicker from 'common/ui/elements/DatePicker'
import formatDate from 'date-fns/format'
import CustomerSearch from 'features/customer-management/ui/modules/CustomerSearch'
import { getAge, isBirthdayToday } from 'lib/ageUtilities'


const $customerOnly = $customer.map((state) => state.present ? state.data : {})
const $addingLoyaltyCard = $customer.map(state => state.present ? { customerUid: state.data.uid } : {})

const searchFormContext = createFormContext('search for customer')
const addingLoyaltyCardFormContext = createFormContext('add loyalty card', $addingLoyaltyCard)
const formContext = createFormContext('edit customer', $customerOnly)

sample({
  source: searchFormContext.$form,
  clock: searchFormContext.submit,
  target: loadCustomerFx,
})

sample({
  source: formContext.$form,
  clock: formContext.submit,
  target: updateCustomerFx
})

sample({
  source: addingLoyaltyCardFormContext.$form,
  clock: addingLoyaltyCardFormContext.submit,
  target: addCustomerLoyaltyCardFx
})

function DisplayProfilePage() {
  const customer = useStore($customer)
  const loyaltyCards = useStore($loyaltyCards)
  const customerDisplayState = useDisplayState([loadCustomerFx])
  const loyaltyCardsDisplayState = useDisplayState(loadCustomerLoyaltyCardsFx)
  const routeParams = useParams()

  const [editingCustomer, setEditingCustomer] = useState(false)
  const [addingLoyaltyCard, setAddingLoyaltyCard] = useState(false)

  useEffect(() => {
    if (routeParams.customerUid)
      loadCustomerFx(routeParams)
  }, [routeParams])

  useEffect(() => {
    const unsubscribeFromCustomerUpdate = updateCustomerFx.finally.watch(() => setEditingCustomer(false))
    const unsubscribeFromLoyaltyCardAdd = addCustomerLoyaltyCardFx.finally.watch(() => closeEditingModal())

    return () => {
      unsubscribeFromCustomerUpdate()
      unsubscribeFromLoyaltyCardAdd()
    }
  }, [])

  const openEditingModal = () => {
    setAddingLoyaltyCard(true)
  }

  const closeEditingModal = () => {
    setAddingLoyaltyCard(false)
    addingLoyaltyCardFormContext.setField({ key: 'cardNumber', value: '' })
  }

  const toggleEditingCustomer = () => {
    if (!editingCustomer) return setEditingCustomer(true)
    return formContext.submit()
  }
  
  return (
    <div className="p-page container-fluid p-customer-search-page">
      {/*<h2>Add new customer</h2>*/}
      <div className="p-page-content">
        <CustomerSearch value={routeParams.customerUid} />
        <Row>
          <React.Fragment display-if={customerDisplayState === 'init'}>
            <Col sm={12}>
              <div className="p-placeholder">
                <Icon className="p-icon" type="scan" size="lg" />
                <h6>Scan customer card, QR, or enter customer phone and hit Enter</h6>
              </div>
            </Col>
          </React.Fragment>
          <React.Fragment display-if={customerDisplayState === 'load'}>
            <Col sm={12}>
              <div className="p-placeholder">
                <Loader />
                <h6>One moment, please...</h6>
              </div>
            </Col>
          </React.Fragment>
          <React.Fragment display-if={customerDisplayState === 'fail'}>
            <Col sm={12}>
              <div className="p-placeholder">
                <Icon className="p-icon" type="construction-sign-large" size="lg" />
                <h6>No customer found matching the search criteria</h6>
              </div>
            </Col>
          </React.Fragment>
          { customerDisplayState === 'view' && customer.present && (
            <Col sm={12}>
              <Card>
                <CardHeader>
                  <span className="customer-outline-title">Customer Outline</span>
                  <Button color={editingCustomer ? "success" : "primary"} icon={editingCustomer ? "save" : "edit"} onClick={toggleEditingCustomer}>
                    { editingCustomer ? 'Save' : 'Edit' }
                  </Button>
                </CardHeader>
                <CardBody>
                  <div className="customer-profile">
                    <div className="main-row">
                      <div className="outline">
                        <div className="p-half">
                          <React.Fragment display-if={!editingCustomer}>
                            <h5>Editable</h5>
                            <div className="meta-container">
                              <MetaBlock name={'First Name'} value={customer.data.firstName} />
                              <MetaBlock name={'Last name'} value={customer.data.lastName} />
                              <MetaBlock
                                name={'Date of birth'}
                                value={formatDate(customer.data.birthDate, 'dd MMMM') + (isBirthdayToday(customer.data.birthDate || new Date()) ? ' (TODAY)' : '')}
                                valueClassName={isBirthdayToday(customer.data.birthDate || new Date()) ? 'text-danger' : undefined} />
                              <MetaBlock name={'Phone'} value={customer.data.phone} />
                              <MetaBlock name={'Bonus balance'} value={customer.data.bonusBalance + ' EZR'} />
                            </div>
                          </React.Fragment>
                          <React.Fragment display-if={editingCustomer}>
                            <h5>Editing</h5>
                            <EffectorForm className={"customer-edit-form"} formContext={formContext}>
                              <EffectorField
                                label="First name"
                                type="text"
                                name="firstName" />
                              <EffectorField
                                label="Last name"
                                type="text"
                                name="lastName" />
                              <EffectorField
                                label="Birth date"
                                type="text"
                                name="birthDate"
                                customComponent={DatePicker} />
                              <EffectorField
                                label="Phone"
                                type="text"
                                name="phone" />
                              <EffectorField
                                label="Bonus balance"
                                type="text"
                                name="bonusBalance" />
                              <FormGroup>
                                <Button type="submit" color="primary" icon="save">Save</Button>
                              </FormGroup>
                            </EffectorForm>
                          </React.Fragment>
                        </div>
                        <hr />
                        <div className="p-half">
                          <h5>View only</h5>
                          <div className="meta-container">
                            <MetaBlock name={'UID'} value={customer.data.uid} />
                            <MetaBlock name={'LTV'} value={customer.data.ltv + ' RUR'} />
                            <MetaBlock name={'Age'} value={getAge(customer.data.birthDate || new Date()).toString()} />
                            <MetaBlock name={'VK'} value={"APP NOT BOUND"} valueClassName="text-warning" />
                          </div>
                        </div>
                        <hr />
                        <div className="p-full">
                          <div className="d-flex align-items-center loyalty-header-container">
                            <h5 className="loyalty-header flex-fill">Loyalty cards</h5>
                            <Button color="primary" icon="add" size="sm" onClick={openEditingModal}>Add new</Button>
                          </div>
                          <Modal isOpen={addingLoyaltyCard} toggle={closeEditingModal}>
                            <ModalHeader toggle={closeEditingModal}>Adding new loyalty card</ModalHeader>
                            <ModalBody>
                              <EffectorForm formContext={addingLoyaltyCardFormContext}>
                                <EffectorField label="Scan card # on card, or enter it manually, then hit Enter" type="text" name="cardNumber" />
                              </EffectorForm>
                            </ModalBody>
                            <ModalFooter>
                              <Button color="success" icon="save" iconSize="sm" onClick={() => addingLoyaltyCardFormContext.submit()}>
                                Add
                              </Button>
                              <Button color="danger" icon="cross" iconSize="sm" onClick={closeEditingModal}>
                                Cancel
                              </Button>
                            </ModalFooter>
                          </Modal>
                          <React.Fragment display-if={loyaltyCardsDisplayState === 'load'}>
                            <div className="p-placeholder">
                              <Loader />
                              <h6>One moment, please...</h6>
                            </div>
                          </React.Fragment>
                          <React.Fragment display-if={loyaltyCardsDisplayState === 'fail'}>
                            <div className="p-placeholder">
                              <Icon className="p-icon" type="construction-sign-large" size="lg" />
                              <h6>Error while loading loyalty cards for the customer</h6>
                            </div>
                          </React.Fragment>
                          { loyaltyCardsDisplayState === 'view' && loyaltyCards.present && (
                            <Table>
                              <thead>
                                <tr>
                                  <th>Card #</th>
                                  <th>Activated on</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  loyaltyCards.data.map(card => (
                                    <tr key={card.cardNumber}>
                                      <th>{card.cardNumber}</th>
                                      <th>{formatDate(card.activationDate!, 'dd.MM.yyyy')}</th>
                                      <th>
                                        <Button
                                          color="danger"
                                          onClick={() => deleteCustomerLoyaltyCardFx({ customerUid: card.customerUid, cardNumber: card.cardNumber })}>
                                          <Icon type="remove" size="sm" />
                                        </Button>
                                      </th>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>                
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </div>
  )
}

export default DisplayProfilePage
