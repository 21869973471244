import is from '@sindresorhus/is'
const isoDate = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/

const jsonParseDate = (_: string, value: any) => {
  if (is.string(value)) {
    if (value.length < 23 && (!value.includes('-') || value.startsWith('-')) && !value.includes(':')) return value;
    let match = isoDate.exec(value)
    if (match) {
      const parsedDate = new Date(value)
      if (!isNaN(parsedDate.getTime())) return parsedDate
    }
  }
  return value
}

export default jsonParseDate
