import React from 'react'
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'reactstrap'
import Icon, { IconType, IconSize } from './Icon'

type ButtonProps = BootstrapButtonProps & {
  icon?: IconType
  iconSize?: IconSize
}

function Button(props: ButtonProps) {
  const { icon, iconSize, children, ...buttonProps } = props
  return (
    <BootstrapButton {...buttonProps}>
      { icon && <Icon className="phantasm-icon__btn-start" type={icon} size={iconSize || 'sm'} />}
      { children }
    </BootstrapButton>
  )
}

export default Button
