import React from 'react'
import ReactDOM from 'react-dom'
import pkg from '../package.json'
import PhantasmApp from './common/ui/modules/PhantasmApp'
import initializeApplication from './common/events/initializeApplication'
import 'react-toastify/dist/ReactToastify.css'
import 'simplebar/dist/simplebar.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import './common/scss/index.scss'

async function main() {
  console.log(`P H A N T A S M --->* (${pkg.version})`)
  console.log(`-> initializing the application`)
  initializeApplication()
  const root = document.createElement('div')
  root.classList.add('phantasm-app')
  document.body.appendChild(root)
  ReactDOM.render(
    <PhantasmApp />,
    root
  )
}

main()
