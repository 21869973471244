import React from 'react'
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
} from 'reactstrap'
import Logo from 'static/logo.svg'

function MainPage() {
  return (
    <div className="wrapper wrapper__align-center">
      <div className="login-content">
        <Container>
          <Row>
            <Col className="offset-lg-3 offset-md-3" lg="6" md="6">
              <Card className="card-login">
                <CardBody>
                  <Logo className="logo" />
                  <h5>Nothing to see here. Move along</h5>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default MainPage
