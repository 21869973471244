import React from 'react'
import Icon, { IconType } from '../elements/Icon'
import cx from 'classnames'
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import { useHistory } from 'react-router-dom'

type FeatureBlockProps = {
  className?: string
  title: string
  icon: IconType
  link: string
  children?: React.ReactChildren
}

function FeatureBlock(
  { className, title, icon, children, link }: FeatureBlockProps
) {
  const history = useHistory()
  return (
    <Card
      className={cx("feature-block", className)}
      onClick={() => history.push(link)}>
      <CardHeader>
        <CardTitle tag="h5">{title}</CardTitle>
      </CardHeader>
      <CardBody>
        <div className="feature-column-container">
          <Icon className="feature-icon" type={icon} size='lg' />
          <div>
            {children}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default FeatureBlock
