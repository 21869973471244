import React from 'react'

import {
  Row,
  Col,
} from 'reactstrap'
import { IconType } from 'common/ui/elements/Icon'
import FeatureBlock from 'common/ui/modules/FeatureBlock'

const features = [
  {
    key: 'check-profile',
    title: 'Search profile',
    description: (
      <>
        For all your customer data checking needs<br />
      </>
    ),
    icon: 'user-search',
    link: '/admin/customers/search'
  },
  {
    key: 'add-customer',
    title: 'Add a customer',
    description: (
      <>
        If a new customer wants to sign up, do it there
      </>
    ),
    icon: 'user-add',
    link: '/admin/customers/add'
  },
  {
    key: 'modify-customers',
    title: 'Modify customers',
    description: (
      <>
        Wrong name, phone or birthday? Adjust it there!
      </>
    ),
    icon: 'user-edit',
    link: '/admin/customers/search'
  },
]

function LandingPage() {
  return (
    <div className="p-page container-fluid p-landing-page">
      <h4 className="landing-heading">Customer management<br />Choose an action from the list</h4>
      <div className="landing-content">
        <Row>
          {
            features.map(feature => (
              <Col lg={4} md={6} sm={6} xs={12} className="d-flex" key={feature.key}>
                <FeatureBlock
                  key={feature.link}
                  className="flex-fill"
                  title={feature.title}
                  icon={feature.icon as IconType}
                  link={feature.link}>
                  {feature.description as unknown as React.ReactChildren}
                </FeatureBlock>
              </Col>
            ))
          }
        </Row>
      </div>
    </div>
  )
}

export default LandingPage
