
import React, { createContext, useContext } from 'react'
import { createStore, createEvent, Event, Store } from 'effector'
import { createEffectorDebugger } from './effectorDebugger'
import { Form, FormGroup, Label, Input } from 'reactstrap'
import { useStoreMap } from 'effector-react'

type ChangeEvent = { key: string, value: string }

type FormContext = {
  $form: Store<any>,
  handleChange: Event<void>,
  setField: Event<ChangeEvent>,
  submit: Event<void>,
  handleSubmit: Function
}

const EffectorFormContext: React.Context<FormContext> = createContext(null as any)

/*
const withEffectorForm = (formName, { onSubmit } = { onSubmit: (_, __) => {} }) => Component => {
  const setField: Event<ChangeEvent> = createEvent(`effector-form set field on ${formName}`)
  setField.watch(createEffectorDebugger(`events.forms.${setField.shortName}`))

  const submit = createEvent(`effector-form submit ${formName}`)
  submit.watch(createEffectorDebugger(`events.forms.${submit.shortName}`))

  const handleChange = setField.prepend((e: any) => ({ key: e.target.name, value: e.target.value }))

  const $form = createStore({}).on(setField, (s, {key, value}) => ({
    ...s,
    [key]: value,
  }))

  sample(
    $form,
    submit,
    onSubmit
  )

  $form.watch(createEffectorDebugger(`stores.forms.${formName}`))

  const handleSubmit = e => {
    e.stopPropagation()
    e.preventDefault()
    submit()
  }

  const formContext = {
    $form,
    handleChange,
    setField,
    submit,
    handleSubmit
  }

  return props => {
    return (
      <EffectorFormContext.Provider value={formContext}>
        <Component {...props} formContext={formContext} />
      </EffectorFormContext.Provider>
    )
  }
}*/

const EffectorForm = ({ formContext, ...rest }) => (
  <EffectorFormContext.Provider value={formContext}>
    <Form {...rest} onSubmit={formContext.handleSubmit} />
  </EffectorFormContext.Provider>
)

const EffectorField = props => {
  const context = useContext(EffectorFormContext)

  return <EffectorUiField {...props} formContext={context} />
}

const EffectorUiField = ({
  name,
  type,
  label,
  formContext,
  customComponent,
  ...inputProps
} = { inputProps: {}}) => {
  const Component = customComponent || Input
  const value = useStoreMap({
    store: formContext.$form,
    keys: [name],
    fn: (values: any) => values[name] || '',
  })
  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Component
        type={type}
        name={name}
        autoComplete="off"
        value={value}
        onChange={formContext.handleChange}
        {...inputProps} />
    </FormGroup>
  )
}

const createFormContext = (formName, $store: Store<any> | undefined = undefined) => {
  const setField: Event<ChangeEvent> = createEvent(`effector-form set field on ${formName}`)
  setField.watch(createEffectorDebugger(`events.forms.${setField.shortName}`))

  const submit = createEvent(`effector-form submit ${formName}`)
  submit.watch(createEffectorDebugger(`events.forms.${submit.shortName}`))

  const handleChange = setField.prepend((e: any) => ({ key: e.target.name, value: e.target.value }))

  const $form = ($store || createStore({})).on(setField, (s, {key, value}) => ({
    ...s,
    [key]: value,
  }))

  $form.watch(createEffectorDebugger(`stores.forms.${formName}`))

  const handleSubmit = e => {
    e.stopPropagation()
    e.preventDefault()
    submit()
  }

  const formContext: FormContext = {
    $form,
    handleChange,
    setField,
    submit,
    handleSubmit
  }

  return formContext
}

export { EffectorForm, EffectorField, createFormContext }
