import { createEffect, sample } from 'effector'
import { createEffectorDebugger } from 'lib/effectorDebugger'
import { createFormContext } from 'lib/effectorForm'
import { post } from 'lib/request'
import { loadCustomerFx } from 'features/customer-management'

const formContext = createFormContext('ecr')

formContext.$form.watch(createEffectorDebugger('stores.pages.ecr.sale'))

type SuccessfulSale = {
  monetaryAmount: number
  bonusCompensation: number
}

const makeSaleFx = createEffect({
  name: 'make a sale',
  handler: state => post<SuccessfulSale>(`/sales`, state)
})

makeSaleFx.done.watch(({ params }) => loadCustomerFx(params))

sample({
  source: formContext.$form,
  clock: formContext.submit,
  target: makeSaleFx,
  fn: state => ({
    ...state,
    monetaryAmount: parseFloat(
      state.monetaryAmount
        ? state.monetaryAmount.replace(',', '.')
        : '0'
    ),
    bonusCompensation: parseFloat(
      state.bonusCompensation
        ? state.bonusCompensation.replace(',', '.')
        : '0'
    ),
  }),
})

export { formContext, makeSaleFx }
