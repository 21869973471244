import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Row, Col, Card, CardHeader, CardBody, CardTitle, FormGroup } from 'reactstrap'
import { EffectorForm, EffectorField, createFormContext } from 'lib/effectorForm'
import Button from 'common/ui/elements/Button'
import DatePicker from 'common/ui/elements/DatePicker'
import { createCustomerFx } from '../../models/customer';
import { sample } from 'effector'
import { toast } from 'react-toastify'


const formContext = createFormContext('add customer')

sample({
  source: formContext.$form,
  clock: formContext.submit,
  target: createCustomerFx,
})

function AddCustomerPage() {
  const history = useHistory()

  useEffect(() => {
    const unsubscribeCreateCustomer = createCustomerFx.done.watch(({ result }) => {
      toast.success(`Customer created!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      
      history.push('/admin/customers/search/' + result.data.uid)
    })
    return () => unsubscribeCreateCustomer()
  }, [])
  return (
    <div className="p-page container-fluid p-cash-register-page">
      {/*<h2>Add new customer</h2>*/}
      <div className="p-page-content">
        <Row>
          <Col lg={{offset: 3, size: 6}} md={{ size: 6, offset: 3 }} sm={12} xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Add new customer</CardTitle>
              </CardHeader>
              <CardBody>
                <EffectorForm formContext={formContext}>
                  <EffectorField
                    label="Customer phone"
                    type="text"
                    name="phone" />
                  <EffectorField
                    label="First name"
                    type="text"
                    name="firstName" />
                  <EffectorField
                    label="Last name"
                    type="text"
                    name="lastName" />
                  <EffectorField
                    label="Birth date"
                    type="text"
                    name="birthDate"
                    customComponent={DatePicker} />
                  <FormGroup>
                    <Button type="submit" color="primary" icon="user-add">
                      Add customer
                    </Button>
                  </FormGroup>
                </EffectorForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddCustomerPage
