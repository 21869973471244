import React, { useEffect } from 'react'

import {
  Col,
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
} from 'reactstrap'

import { useStore } from 'effector-react'

import Button from 'common/ui/elements/Button'
import Icon from 'common/ui/elements/Icon'
import { $customer, loadCustomerFx } from 'features/customer-management'
import { formContext, makeSaleFx } from '../../models/sale'
import { EffectorField, EffectorForm } from 'lib/effectorForm'
import Loader from 'common/ui/elements/Loader'
import MetaBlock from 'common/ui/elements/MetaBlock'
import useDisplayState from 'lib/useDisplayState'
import { getAge, isBirthdayToday } from 'lib/ageUtilities'
import formatDate from 'date-fns/format'
import { toast } from 'react-toastify'


function CashRegisterPage() {
  const customer = useStore($customer)
  const customerDisplayState = useDisplayState(loadCustomerFx)
  const sale = useStore(formContext.$form)
  const saleInProgress = useStore(makeSaleFx.pending)
  
  useEffect(() => {
    const unsubscribeFromMakeSale = makeSaleFx.done.watch(({ result }) => {
      toast.success(`All good. Take payment for ${(result.data.monetaryAmount).toFixed(2)}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })

      loadCustomerFx(sale)
    })

    return () => unsubscribeFromMakeSale()
  }, [])

  const handleCustomerIdKeyPress = e => {
    if (e.key !== 'Enter') return
    e.stopPropagation()
    e.preventDefault()
    loadCustomerFx(sale)
  }

  return (
    <div className="p-page container-fluid p-cash-register-page">
      <h2>Go on, make a sale</h2>
      <div className="p-page-content">
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h5">ECR</CardTitle>
              </CardHeader>
              <CardBody>
                <EffectorForm formContext={formContext} disabled={saleInProgress}>
                  <EffectorField
                    label="Customer phone, QR or card"
                    type="text"
                    name="customerUid"
                    onKeyPress={handleCustomerIdKeyPress} />
                  <EffectorField
                    label="Amount (RUB)"
                    type="text"
                    name="monetaryAmount" />
                  <EffectorField
                    label={<>Customer wants to use <span className="bg-primary">X</span> bonuses</>}
                    type="text"
                    name="bonusCompensation" />
                  <FormGroup>
                    <Button disabled={saleInProgress as boolean} type="submit" color="primary" icon="money-time">Make a sale</Button>
                  </FormGroup>
                </EffectorForm>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <Card className="customer-profile-container">
              <CardHeader>
                <CardTitle tag="h5">Customer ID</CardTitle>
              </CardHeader>
              <CardBody>
                <React.Fragment display-if={customerDisplayState === 'load'}>
                  <Loader />
                  <h6>One moment...</h6>
                </React.Fragment>
                <React.Fragment display-if={customerDisplayState === 'init'}>
                  <div className="state-icon">
                    <Icon type="scan" size="lg" />
                  </div>
                  <h6>Scan a QR, customer card, or enter customer phone and hit Enter on the left</h6>
                </React.Fragment>
                <React.Fragment display-if={customerDisplayState === 'fail'}>
                  <div className="state-icon">
                    <Icon type="construction-sign-large" size="lg" />
                  </div>
                  <h6>No customer found. Advise customer to sign up</h6>
                </React.Fragment>
                {customerDisplayState === 'view' && customer.present && (
                  <div className="customer-profile">
                    <div className="main-row">
                      <div className="outline">
                        <h5>{customer.data.firstName} {customer.data.lastName}</h5>
                        <div className="meta-container">
                          <MetaBlock name={'Bonus balance'} value={`${customer.data.bonusBalance} EZR`} />
                          <MetaBlock name={'LTV'} value={`${customer.data.ltv} RUR`} />
                          <MetaBlock name={'Age'} value={getAge(customer.data.birthDate || new Date()).toString()} />
                          <MetaBlock
                            name={'Date of birth'}
                            value={formatDate(customer.data.birthDate, 'dd MMMM') + (isBirthdayToday(customer.data.birthDate || new Date()) ? ' (TODAY)' : '')}
                            valueClassName={isBirthdayToday(customer.data.birthDate || new Date()) ? 'text-danger' : undefined} />
                          <MetaBlock name={'Phone'} value={customer.data.phone} />
                          <MetaBlock name={'VK'} value={"APP NOT BOUND"} valueClassName="text-warning" />
                        </div>
                      </div>
                      <div className="avatar">
                        <Icon type='user' size='lg' />
                      </div>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CashRegisterPage
