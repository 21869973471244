import CustomerLandingPage from './pages/landing'
import AddCustomerPage from './pages/add-customer'
import SearchProfilePage from './pages/search-profile'
import DisplayProfilePage from './pages/display-profile'
import { getCustomer, createCustomer, updateCustomer } from './api'
import { loadCustomerFx, $customer } from './models/customer'

export * from './types'

export {
  CustomerLandingPage,
  AddCustomerPage,
  SearchProfilePage,
  DisplayProfilePage,
  getCustomer,
  createCustomer,
  updateCustomer,
  loadCustomerFx,
  $customer
}
