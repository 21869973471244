import React from 'react'

import {
  Row,
  Col,
} from 'reactstrap'
import { IconType } from 'common/ui/elements/Icon'
import FeatureBlock from 'common/ui/modules/FeatureBlock'

const features = [
  {
    title: 'Sell time',
    description: (
      <>
        Need to sell time to the customer in the loyalty program?<br />
        Over there!
      </>
    ),
    icon: 'cash-register',
    link: '/admin/cash-register'
  },
  {
    title: 'Manage customers',
    description: (
      <>
        New customer wants to sign up?<br />
        Need to check customer-related data?<br />
        Need to top-up or deduct customer balance?<br />
        You're in for a treat
      </>
    ),
    icon: 'users',
    link: '/admin/customers'
  },
  {
    title: 'Manage cards',
    description: (
      <>
        Customer lost their card?<br />
        It's all there
      </>
    ),
    icon: 'credit-card',
    link: '/admin/customers/search'
  }
]

function LandingPage() {
  return (
    <div className="p-page container-fluid p-landing-page">
      <h4 className="landing-heading">Welcome to Phantasm<br />What would you like to do?</h4>
      <div className="landing-content">
        <Row>
          {
            features.map(feature => (
              <Col lg={4} md={6} sm={6} xs={12} className="d-flex">
                <FeatureBlock
                  key={feature.link}
                  className="flex-fill"
                  title={feature.title}
                  icon={feature.icon as IconType}
                  link={feature.link}>
                  {feature.description as unknown as React.ReactChildren}
                </FeatureBlock>
              </Col>
            ))
          }
        </Row>
      </div>
    </div>
  )
}

export default LandingPage
