import React from 'react'

import Icon from 'common/ui/elements/Icon'

export default function Loader() {
  return (
    <div className="loader">
      <Icon className='loader-anim' type='loader' size='lg' />
    </div>
  )
}
