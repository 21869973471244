import React, { useState } from 'react'
import {
  Card,
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'
import Logo from 'static/logo.svg'
import Loader from 'common/ui/elements/Loader'
import Icon from 'common/ui/elements/Icon'
import Button from 'common/ui/elements/Button'


function InitAuthPage() {
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const handleSubmit = evt => {
    evt.stopPropagation()
    evt.preventDefault()
    evt.persist()
    setLoading(true)
    fetch(
      process.env.REACT_APP_API_BASE + '/sessions',
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ username })
      }
    ).then(x => x.json())
    .then(response => {
      window.location.href = response.data.url
    })
    .catch(x => console.log('err', x))
  }
  return (
    <div className="wrapper wrapper__align-center">
      <div className="login-content">
        <Container>
          <Row>
            <Col className="offset-lg-3 offset-md-3" lg="6" md="6">
              <Card className="card-login">
                <CardBody>
                  <Logo className="logo" />
                  { loading && <Loader /> }
                  { !loading && 
                    <Form onSubmit={handleSubmit}>
                      <InputGroup className="username-form">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Icon type='user' size='sm' />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Username"
                          type="text"
                          name="username"
                          onChange={e => setUsername(e.target.value)}
                        />
                        <div>
                          <InputGroupText className="ezgaming-domain">
                            @ezgaming.gg
                          </InputGroupText>
                        </div>
                      </InputGroup>
                      <Button
                        type="submit"
                        className="login-btn"
                        color="primary"
                        icon="log-in"
                      >Log in</Button>
                    </Form>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default InitAuthPage
