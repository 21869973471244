import jwtDecode from 'jwt-decode'
import { createStore, Store } from 'effector'
import { expireToken, setToken }from '../events'
import initializeApplication from '../../../common/events/initializeApplication';
import { createEffectorDebugger } from '../../../lib/effectorDebugger';

type TokenState = {
  initialized: true
  token?: string
  exp?: number
} | {
  initialized: false,
}

const token: Store<TokenState> = createStore({ initialized: false })

let timeoutId: any = null

token.on(
  setToken,
  (_, token) => {
    const decodedJwt = jwtDecode(token)
    return { token, exp: decodedJwt.exp, initialized: true }
  }
)

token.on(
  expireToken,
  () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
    return { initialized: true }
  }
)

initializeApplication.watch(() => {
  const token = window.localStorage.getItem('phantasm:jwt')
  if (!token) return expireToken()
  return setToken(token)
})

token.watch(state => {
  if (!state.initialized || !state.exp) return
  if (state.exp < (Date.now() / 1000)) return expireToken()
  timeoutId = setTimeout(
    () => expireToken(),
    (state.exp - Date.now() / 1000) * 1000
  )
})

token.watch(state => {
  if (!state.initialized) return
  window.localStorage.setItem('phantasm:jwt', state.token || '')
})

token.watch(createEffectorDebugger('stores.token'))

export default token
