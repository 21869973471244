import React from 'react'
import UserIcon from 'static/icons/user.svg'
import LoaderIcon from 'static/icons/loader.svg'
import ArrowLeft from 'static/icons/arrow-left.svg'
import LogIn from 'static/icons/log-in.svg'
import LogOut from 'static/icons/log-out.svg'
import HamburgerMenu from 'static/icons/hamburger-menu.svg'
import Countdown from 'static/icons/countdown.svg'
import MoveLeft from 'static/icons/move-left.svg'
import MoveRight from 'static/icons/move-right.svg'
import CashRegister from 'static/icons/cash-register.svg'
import Users from 'static/icons/users.svg'
import CreditCard from 'static/icons/credit-card.svg'
import Coins from 'static/icons/coins.svg'
import MoneyTime from 'static/icons/money-time.svg'
import ConstructionSignLarge from 'static/icons/construction-sign-large.svg'
import Scan from 'static/icons/scan.svg'
import UserAdd from 'static/icons/user-add.svg'
import UserEdit from 'static/icons/user-edit.svg'
import UserSearch from 'static/icons/user-search.svg'
import Edit from 'static/icons/edit.svg'
import Save from 'static/icons/save.svg'
import Remove from 'static/icons/remove.svg'
import Cross from 'static/icons/cross.svg'
import Add from 'static/icons/add.svg'


const icons = {
  add: Add,
  user: UserIcon,
  loader: LoaderIcon,
  countdown: Countdown,
  users: Users,
  coins: Coins,
  scan: Scan,
  edit: Edit,
  save: Save,
  remove: Remove,
  cross: Cross,
  'user-add': UserAdd,
  'user-edit': UserEdit,
  'user-search': UserSearch,
  'arrow-left': ArrowLeft,
  'log-in': LogIn,
  'log-out': LogOut,
  'hamburger-menu': HamburgerMenu,
  'move-left': MoveLeft,
  'move-right': MoveRight,
  'cash-register': CashRegister,
  'credit-card': CreditCard,
  'money-time': MoneyTime,
  'construction-sign-large': ConstructionSignLarge,
}

export type IconSize = 'sm' | 'md' | 'lg'
export type IconType = keyof typeof icons

interface IconProps {
  className?: string,
  size: IconSize,
  type: IconType
}

const Icon = (
  { className, size, type, ...rest }: IconProps = { size: 'sm', type: 'user' }
) => {
  if (!icons[type]) throw new Error(`Icon ${type} not found. Please check the imports`)
  return React.createElement(
    icons[type],
    {
      className: [
        `phantasm-icon`,
        `phantasm-icon__${size}`,
        className || ''
      ].join(' '),

    }
  )
}

export default Icon
