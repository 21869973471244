import { differenceInYears, getMonth, getDate } from 'date-fns'

const getAge = (birthDate: Date) => differenceInYears(today(), birthDate)

const isBirthdayToday = (birthDate: Date) => (
  getMonth(birthDate) === getMonth(today()) &&
  getDate(birthDate) === getDate(today())
)

const today = () => new Date()

export { getAge, isBirthdayToday }
