import React from 'react'
import cx from 'classnames'

type MetaBlockProps = {
  name: string
  value: string
  nameClassName?: string
  valueClassName?: string
}

function MetaBlock(
  { name, value, nameClassName, valueClassName }: MetaBlockProps =
  { name: '', value: '', nameClassName: '', valueClassName: '' }) {
  return (
    <div className="meta-block">
      <div className={cx("key", nameClassName)}>{name}</div>
      <div className={cx("value", valueClassName)}>{value}</div>
    </div>
  )
}

export default MetaBlock
