import { useEffect, useState } from 'react'
import { Effect, createStore, createEvent } from 'effector'
import { createEffectorDebugger } from './effectorDebugger'
import entriesToObject from './entriesToObject'

type OurEffect = Effect<unknown, unknown, unknown>
type DisplayState = 'view' | 'load' | 'fail' | 'init'

const useDisplayState = (effectOrEffects: OurEffect | OurEffect[]) => {
  const [displayState, setDisplayState] = useState<DisplayState>('init')
  useEffect(() => {    
    const effectsArray = Array.isArray(effectOrEffects) ? effectOrEffects : [ effectOrEffects ]
    type EffectDoneStore = {[x: string]: boolean}
    const effectsStoreInitialValue = {}
    const $effectsStore = createStore<EffectDoneStore>(effectsStoreInitialValue)
    const effectLoading = createEvent<number>('useDisplayState loading')
    const effectDone = createEvent<number>('useDisplayState done')
    const effectFailed = createEvent<number>('useDisplayState failed')
    const $firstRender = createStore<boolean>(true)
    const setNotFirstRender = createEvent('useDisplayState not first render')

    $firstRender.on(setNotFirstRender, () => false)

    $effectsStore
      .on(effectLoading, (state, index) => entriesToObject(
        Object.entries(state).filter(([k]) => k !== `effect ${index}`))
      )
      .on(effectDone, (state, index) => ({ ...state, [`effect ${index}`]: true }))
      .on(effectFailed, (state, index) => ({ ...state, [`effect ${index}`]: false }))

    

    const unsubscribersArray = effectsArray.flatMap((effect: OurEffect, idx: number) => [
      effect.watch(() => effectLoading(idx)),
      effect.done.watch(() => effectDone(idx)),
      effect.fail.watch(() => effectFailed(idx)),
    ])

    const unsubscribeFromStoreChanges = $effectsStore.watch(state => {
      if (state === effectsStoreInitialValue) return setDisplayState('init')
      if (Object.keys(state).length === 0 && $firstRender.getState()) {
        setDisplayState('init')
        setNotFirstRender()
      }
      if (Object.keys(state).length !== effectsArray.length) return setDisplayState('load')
      if (Object.values(state).every(x => x)) return setDisplayState('view')
      return setDisplayState('fail')
    })

    return () => {
      unsubscribersArray.map(unsubscribe => unsubscribe())
      unsubscribeFromStoreChanges()
    }
  }, [])

  return displayState
}


export default useDisplayState
