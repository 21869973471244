import React, { useState, useEffect } from 'react'
import {
  Card,
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'
import Logo from 'static/logo.svg'
import Icon from 'common/ui/elements/Icon'
import Button from 'common/ui/elements/Button'




function NotFoundPage() {
  return (
    <div className="wrapper wrapper__align-center">
      <div className="login-content">
        <Container>
          <Row>
            <Col className="offset-lg-3 offset-md-3" lg="6" md="6">
              <Card className="card-login">
                <CardBody>
                  <Button
                    color="primary"
                    icon="arrow-left"
                    onClick={() => window.history.back()}>
                    Go back
                  </Button>
                  <Logo className="logo" />
                  <h5>Page not found</h5>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default NotFoundPage
