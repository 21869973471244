import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AppWrapper from './AppWrapper'
import { InitAuthPage, LandAuthPage } from 'features/authentication'
import { NotFoundPage } from 'features/notfound'
import MainPage from '../pages/main'
import { ToastContainer } from 'react-toastify'


function PhantasmApp() {
  return (
    <Router>
      <ToastContainer />
      <Switch>
        <Route exact path="/authenticate" component={InitAuthPage} />
        <Route exact path="/authenticate/land" component={LandAuthPage} />
        <Route path="/admin" component={AppWrapper} />
        <Route exact path='/' component={MainPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  )
}

export default PhantasmApp
