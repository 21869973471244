import React, { useCallback, useMemo } from 'react'
import { Input } from 'reactstrap'
import UnwrappedDatePicker from 'react-datepicker'
import is from '@sindresorhus/is'

const DatePicker = ({ value, onChange, name, ...rest }) => {
  const handleChange = useCallback(value => onChange({ target: { name, value }}), [ name, onChange ])
  const realValue = useMemo(() => {
    if (!value || value === '') {
      handleChange(new Date())
      return new Date()
    }
    if (is.string(value)) return new Date(value)
    return value
  }, [value])
  return (
    <UnwrappedDatePicker
      selected={realValue}
      name={name}
      onChange={handleChange}
      customInput={<Input />}
      dateFormat={"dd.MM.yyyy"}
      showYearDropdown
      {...rest} />
  )
}

export default DatePicker
