import { token } from 'features/authentication'
import { toast } from 'react-toastify'
import jsonParseDate from './jsonParseDate'

type ErraticApiResponse = { errors: string[] }
interface SuccessfulApiResponse<T> {
  data: T
}

const transformQuery = query => (
  Object
    .pairs(query)
    .map(([k, v]) => [k, encodeURIComponent(v)].join('='))
    .join('&')
)

const prepareUrl = (url, query) => (
  Object.keys(query).length !== 0
    ? [url, transformQuery(query)].join('?')
    : url
)

type KVMap = { [x:string]: string | number | boolean | KVMap }
type StringMap = { [x:string]: string }

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

type RequestParameters = {
  method: RequestMethod,
  query?: KVMap,
  body?: KVMap,
  headers?: StringMap
}

const request = <T>(url, { query, body, headers, method }: RequestParameters): Promise<SuccessfulApiResponse<T>> => {
  console.log(`-> request: ${method} ${url}`)
  return fetch(
    prepareUrl(
      [process.env.REACT_APP_API_BASE, url].join(url.startsWith('/') ? '' : '/'),
      query
    ),
    {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(
          token.getState().token
            ? { 'x-token': token.getState().token }
            : {}
        ),
        ...headers
      },
      body: body && JSON.stringify(body),
    }
  )
  .then(async res => {
    if (res.ok) {
      const text = await res.text()
      try {
        return JSON.parse(text, jsonParseDate)
      } catch (e) {
        throw res
      }
    }
    throw res
  })
  .catch(async res => {
    console.log(`-> request: ${method} ${url} FAIL (${res.status} ${res.statusText})`)
    const json: ErraticApiResponse = await res.json()
    json.errors.forEach(error => {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    })
    throw json
  })
}

const get = <T>(url, query = {}) => request<T>(url, { method: 'GET', query })
const del = <T>(url, query = {}, params = {}) => request<T>(url, { method: 'DELETE', query, ...params })
const post = <T>(url, body = {}, query = {}, params = {}) => request<T>(url, { method: 'POST', query, body, ...params })
const put = <T>(url, body = {}, query = {}, params = {}) => request<T>(url, { method: 'PUT', query, body, ...params })


export { request, get, post, put, del }


