import { get, put, post, del } from 'lib/request'
import { Customer, LoyaltyCard } from './types'

const prepareUid = (uid: string) => uid.replace(/\s+/g, '').trim()

const getCustomer = (customerUid: string) => get<Customer>(`/customers/${prepareUid(customerUid)}`)
const updateCustomer = (body: Customer) => put<Customer>(`/customers/${body.uid}`, body)
const createCustomer = (body: Partial<Customer>) => post<Customer>(`/customers`, body)

const getLoyaltyCardsForCustomer = (customerUid: string) => get<LoyaltyCard[]>(`/customers/${prepareUid(customerUid)}/loyalty-cards`)
const deleteLoyaltyCard = (customerUid: string, cardUid: string) => del<any>(`/customers/${prepareUid(customerUid)}/loyalty-cards/${cardUid}`)
const createLoyaltyCard = (customerUid: string, body: Partial<LoyaltyCard>) => post<LoyaltyCard>(`/customers/${prepareUid(customerUid)}/loyalty-cards`, body)



export {
  getCustomer,
  updateCustomer,
  createCustomer,
  getLoyaltyCardsForCustomer,
  deleteLoyaltyCard,
  createLoyaltyCard
}
