export class DataRecord<T> implements Record<T> {
  present: true = true;
  data: T

  constructor(t: T) {
    this.data = t
  }
  setData(t: T) {
    return new DataRecord(t)
  }
}

export class NoRecord<T> implements Record<T> {
  present: false = false;
  setData(t: T) {
    return new DataRecord<T>(t)
  }
}

export interface Record<T> {
  setData(T);
}

export function createRecord<T>(data?: T): NoRecord<T> | DataRecord<T> {
  if (!data) return new NoRecord<T>()
  return new DataRecord<T>(data)
}
