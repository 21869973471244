import React from 'react'
import cx from 'classnames'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { withAuthentication } from 'features/authentication'
import Navbar from './Navbar'
import Sidebar from './Sidebar';
import { $sidebar } from 'common/models/sidebar'
import { useStore } from 'effector-react'
import { LandingPage } from 'features/admin-landing'
import { CashRegisterPage } from 'features/cash-register'
import { AddCustomerPage, CustomerLandingPage, SearchProfilePage, DisplayProfilePage } from 'features/customer-management'
import SimpleBar from 'simplebar-react'

function AppWrapper() {
  const sidebarOpen = useStore($sidebar)
  return (
    <React.Fragment>
      <Navbar />
      <div className={
        cx(
          "phantasm-app-body phantasm-sidebar-fixed sidebar-lg-show",
          {'sidebar-minimized': !sidebarOpen}
        )
      }>
        <Sidebar />
        <SimpleBar style={{ maxHeight: '100vh' }} className="main phantasm-main">
          <main className="main phantasm-main">
            <Route exact path='/admin' component={LandingPage} />
            <Route path='/admin/cash-register' component={CashRegisterPage} />
            <Route exact path='/admin/customers' component={CustomerLandingPage} />
            <Route exact path='/admin/customers/add' component={AddCustomerPage} />
            <Route exact path='/admin/customers/search' component={SearchProfilePage} />
            <Route exact path='/admin/customers/search/:customerUid' component={DisplayProfilePage} />
          </main>
        </SimpleBar>
      </div>
    </React.Fragment>
  )
}

//export default AppWrapper

export default withAuthentication(
  () => <Redirect to='/authenticate' />
)(AppWrapper)

