import React, { useState, useEffect } from 'react'
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
} from 'reactstrap'
import Logo from 'static/logo.svg'
import Loader from 'common/ui/elements/Loader'
import Icon from 'common/ui/elements/Icon'
import { setToken } from 'features/authentication'



function LandAuthPage() {
  const fetchToken = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_BASE + '/sessions/callback?' + window.location.hash.split('#')[1],
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'GET',
      }
    ).then(x => x.json())
    .catch(x => console.log('err', x))

    if (!response) return
    if (response.errors.length > 0) {
      // TODO: notification on error pls
      return
    }
    if (!response.data.token) {
      // TODO: failed to get token
      return
    }
    setToken(response.data.token)
    window.location.href = '/admin'
    // http://localhost:3000/authenticate/land#access_token=AgAEA7qivcrAAAYNTC4A_c30i0unnTeCL6m33DQ&token_type=bearer&expires_in=453254
  }

  useEffect(() => {
    fetchToken()
  })

  return (
    <div className="wrapper wrapper__align-center">
      <div className="login-content">
        <Container>
          <Row>
            <Col className="offset-lg-3 offset-md-3" lg="6" md="6">
              <Card className="card-login">
                <CardBody>
                  <Logo className="logo" />
                  <h5>Logging you in...</h5>
                  <Loader />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default LandAuthPage
