import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { EffectorForm, EffectorField, createFormContext } from 'lib/effectorForm'

const searchFormContext = createFormContext('search for customer')

/* FIXME: Is it a good idea to abstract business logic here? */

function CustomerSearch({ value } = { value: '' }) {
  const history = useHistory()

  useEffect(() => {
    searchFormContext.setField({ key: 'customerUid', value: value })
  }, [value])

  useEffect(() => {
    const unwatchSearchFormSubmit = searchFormContext.submit.watch(() => {
      history.push('/admin/customers/search/' + searchFormContext.$form.getState().customerUid)
    })

    return () => unwatchSearchFormSubmit()
  }, [])

  return (
    <Row>
      <Col sm={12}>
        <EffectorForm formContext={searchFormContext}>
          <EffectorField
            label="Customer phone, QR or card number"
            type="text"
            name="customerUid" />
        </EffectorForm>
      </Col>
    </Row>
  )
}

export default CustomerSearch
