import React from 'react'
import { useStore } from 'effector-react'
import token from '../../models/token'

const withAuthentication = AuthenticationError => Component => props => {
  const state = useStore(token)
  if (state.initialized && !state.token) return <AuthenticationError />
  return <Component token={state.token!} />
}

export default withAuthentication
