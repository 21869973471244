import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import Logo from 'static/logo.svg'
import Icon from '../elements/Icon'
import { Link } from 'react-router-dom'
import { useStore } from 'effector-react'
import { token } from 'features/authentication'
import { $sidebar, toggleSidebar } from 'common/models/sidebar'

function Navbar() {
  const tokenStore = useStore(token)
  const sidebarStore = useStore($sidebar)
  const [timeRemaining, setTimeRemaining] = useState('0:0')
  useEffect(() => {
    const updateTimer = () => {
      if (!tokenStore.initialized) return
      if (!tokenStore.exp) return
      const secondsDiff = Math.ceil(tokenStore.exp - (Date.now() / 1000))
      const hourDiff = Math.floor(secondsDiff / 3600)
      const minuteDiff = Math.ceil(((secondsDiff - (2 * hourDiff)) / 60) - (hourDiff * 60))

      setTimeRemaining(`${hourDiff}:${minuteDiff}`)
    }
    updateTimer()
    let intervalId = setInterval(updateTimer, 3000)
    return () => clearInterval(intervalId)
  })
  
  return (
    <header className="phantasm-app-header phantasm-navbar">
      <Link className="phantasm-navbar-branding" to="/admin">
        <Logo className="logo" />
      </Link>
      <a
        onClick={() => toggleSidebar()}
        className={cx('sidebar-toggle', { active: sidebarStore})}>
        <Icon
          type="hamburger-menu"
          size="lg" />
      </a>
      <ul className="ml-auto phantasm-navbar-nav">
        <li>
          <Icon className="session-countdown" type='countdown' size='sm' />
          <span>
            {timeRemaining}
          </span>
        </li>
      </ul>
      {/*
      <Icon className="sidebar-toggle" type="hamburger-menu" size="lg" />
      */
      }
    </header>
  )
}


export default Navbar
