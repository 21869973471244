import { createStore, createEvent, Store, Event } from 'effector'
import { createEffectorDebugger } from 'lib/effectorDebugger'

const toggleSidebar = createEvent('toggle sidebar')
const setSidebarState: Event<boolean> = createEvent('set sidebar state')

const $sidebar: Store<boolean> = createStore(true)

$sidebar.on(toggleSidebar, state => !state)

$sidebar.on(setSidebarState, (_, payload) => payload)

$sidebar.watch(createEffectorDebugger('store.sidebar'))

export { $sidebar, toggleSidebar, setSidebarState }
